@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import "../src/assets/scss/custom-variables.scss";

* {
  box-sizing: border-box;
}

.p-0 {
  padding: 0 !important;
}

html,
body {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

.h-100 {
  height: 100%;
}

.lead-frm-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  img {
    height: 60px;
  }
}


.lead-frm-input {
  .Mui-focused {
    color: #000 !important;

  }

  .MuiSelect-select {
    background-color: #fff !important;
  }
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
}

.custom-btn {
  white-space: nowrap;
}

.navigation {
  display: flex;
  white-space: nowrap;

  li {
    margin-left: 24px;

    a {
      text-decoration: none;
      color: #2B2B2B;
      position: relative;
      transition: all 250ms ease-in-out;

      &:hover {
        color: $primary;
      }

      &.active {
        color: $primary;

        &::before {
          content: "";
          position: absolute;
          bottom: -2px;
          height: 2px;
          width: 100%;
          left: 0;
          background-color: $primary;
        }
      }
    }
  }
}

.header-logo {
  width: 130px;
  padding: 6px;
  img {
    width: 100%;
  }
}

.banner-section {
  min-height: 220px;
  padding: 40px 0;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }



  .banner-content {
    position: relative;
    text-align: left;

    * {
      color: $white;
    }

    .text-green {
      color: #DC143C !important;
    }

    // h1 {
    //   font-weight: bold;
    //   margin-bottom: 7px;
    //   font-size: 34px;
    // }

    // h1 {
    //   font-weight: bold;
    //   margin-bottom: 7px;
    //   font-size: 32px;
    // }
  }
}

.banner-head-info {
  background-color: #DC143C;
}

.MuiCard-root {
  border: 1px solid $gray-200;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06) !important;
  border-radius: 10px !important;
}

.most-list {
  padding-left: 4px;
  margin-top: 10px;
  li {
    margin-bottom: 10px;
    text-indent: -18px;
    padding-left: 18px;
    list-style: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
  }
  li::before {
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    background-color: #636060;
    border-radius: 50%;
    margin-right: 12px;
    margin-bottom: 2px;
  }
}

.listing-card {
  display: flex;
  margin-bottom: 30px;
  cursor: pointer;

  .list-rank-count {
    position: absolute;
    left: 0;
    top: 0;
    background-color: $gray-300;
    font-size: 16px;
    height: 38px;
    min-width: 38px;
    border-bottom-right-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-indent: -3px;

    &.first {
      background-color: #DAA520;
      color: $white;
    }
  }

  .info-1 {
    max-width: 200px;
    min-width: 200px;
    padding: 65px 16px 40px;
    border-right: 1px solid $gray-200;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .popular-tag {
      padding: 6px 10px;
      border: 1px solid $primary;
      background-color: #EEFCFF;
      color: $primary;
      text-align: center;
      border-radius: 4px;
    }

    a {
      text-decoration: none;
      color: $gray-900;
      cursor: pointer;
    }
  }

  .info-2 {
    flex-grow: 1;
    padding: 16px;

    .brand-name {
      text-align: center;
    }
    
    .subtitle {
      display: flex;
      gap: 8px;

      // &::before {
      //   content: '';
      //   min-width: 24px;
      //   height: 18px;
      //   background-image: url("assets/images/icons/cap.svg");
      //   background-size: cover;
      //   margin-top: 5px !important;
      // }
    }

    ul {
      padding: 10px 0;
      margin: 0 auto;

      li {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        font-size: 14px;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          content: '';
          position: relative;
          top: 4px;
          min-width: 18px;
          height: 18px;
          background-image: url("assets/images/icons/check.svg");
        }
      }
    }
  }
}

.header-logo {
  width: 130px;
  padding: 6px;

  img {
    width: 100%;
  }
}

.lead-frm-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    height: 60px;
  }
}

.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: 10px;
}

.radio-btn {
  width: 48%;
  border: 1px solid #dfdfdf;
  background-color: #fff;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 8px
}

// .progress-bar {
//   width: 360px;
//   height: 8px;
//   margin: 0 auto;
// }

.total-count-logo {
  width: 120px;
  display: block;
  margin-left: 20px;
  margin-right: 20px;

  img {
    width: 180%;
  }
}

.frm-card {
  border: none;
  cursor: pointer;
  outline: 1px solid #ccc;

  &.selected,
  &:hover {
    outline: 2px solid #4fab78;
    transition: all 50ms ease;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08) !important;
  }
}

.top-picks-card {
  flex-direction: column;

  .info-1 {
    width: 100%;
    max-width: 100%;
    flex-direction: row;
    padding: 24px;
    background-color: #fff;
    border-right: none;
    border-bottom: 1px solid $gray-200;

    .count {
      font-size: 30px;
      font-weight: 800;
    }
  }

  .info-2 {
    padding: 16px !important;

    ul {
      padding-bottom: 0;
    }
  }
}

.top-picks-card img {
  width: 140px;
}

.top-picks-card .info-1 a {
  color: white;
}

.custom-select {
  .MuiInputBase-root {
    background-color: $white !important;
    border: 1px solid $gray-200;
    border-radius: 8px !important;
    overflow: hidden;

    &::before,
    &::after {
      display: none;
    }

    .MuiInputBase-input {
      font-size: 20px;

      &:focus {
        background-color: $white;
      }
    }
  }

  label {
    text-transform: uppercase;
    font-weight: 600;
    color: $gray-800 !important;
  }
}

.custom-error {
  border: 1px solid rgb(241, 101, 101) !important;
  border-radius: 8px !important;
  .MuiSvgIcon-root {
    color: rgb(241, 101, 101) !important;
  }
  label {
    text-transform: uppercase;
    font-weight: 600;
    color: rgb(241, 101, 101) !important;
  }
}

.MuiMenu-paper {
  border-radius: 8px;
}

.editorial-reviews {
  h2 {
    background-color: #EDEDED;
    font-size: 24px;
    padding: 16px;
  }

  .list-box {
    border-bottom: 1px solid $gray-200;

    &:last-child {
      border: none;
    }

    img {
      min-width: 40px;
      width: 40px;
    }

    a {
      cursor: pointer;
      font-size: 13px;
    }
  }
}

.footer {
  background-color: $white;
  padding: 40px 0 0;

  .footer-link {
    padding: 0;
    background-color: transparent !important;
    color: #2b2b2b;
    text-transform: none !important;
    font-weight: 600;
  }

  img {
    width: 100%;
  }

  .footer-links {
    padding: 10px 0;
    background-color: $gray-300;
    // margin-top: 40px;
    border-top: 1px solid $gray-400;

    >div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        color: $gray-900;
        text-decoration: none;
        font-weight: 600;
        font-size: 14px;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  .copyright {
    background-color: #2B2B2B;
    color: $white;
    text-align: center;
    font-size: 13px;
    padding: 5px;
  }
}

.faq {
  // background-color: #DAA520;

  .MuiAccordion-root {
    background-color: transparent;
    box-shadow: none !important;
    border-radius: 0 !important;
    padding: 16px 0;
    border-bottom: 1px solid #80D5F7;
    margin: 0 !important;

    * {
      color: $white;
    }

    &::before {
      display: none;
    }

    .MuiAccordionSummary-root {
      min-height: 0;
      border-radius: 0;
      padding: 0;
      transition: all 250ms ease;

      &.Mui-expanded {
        margin-bottom: 10px;

      }

      .MuiAccordionSummary-content {
        margin: 0;
      }
    }

    .MuiSvgIcon-root {
      font-size: 30px;
    }
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }
}

.blog-info.info-2 {
  padding-top: 150px;
}

.mobile-menu {
  div:first-child {
    padding: 20px;

    img {
      width: 150px;
    }
  }

  ul {
    padding: 0;

    li {
      display: block;

      a {
        display: block;
        padding: 8px 20px;
        text-decoration: none;
        color: #000;

        &:hover {
          background-color: #dfdfdf;
        }
      }
    }
  }
}

.custom-loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%, #4096DD) content-box;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg, #000 1deg 20deg, #0000 21deg 36deg),
    radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 calc(100% - 8px));
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: s4 1s infinite steps(10);
}

@keyframes s4 {
  to {
    transform: rotate(1turn)
  }
}

// Blog Details
.short-info {
  background-color: $white;
  border-bottom: 2px solid $gray-300;
  position: fixed;
  top: 71px;
  left: 0;
  width: 100%;
  z-index: 2;

  .custom-btn {
    min-width: 190px;
  }

  .img-wrapper img {
    max-width: 115px;
  }
}

.blog-info {

  .img-wrapper img {
    max-width: 650px;
    border-radius: 8px;
    display: block;
  }

  .blog-info-box {
    background-color: $white;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    width: 500px;
    position: relative;
    margin-left: -150px;

    img {
      width: 72px;
    }
  }

  .subtitle {
    &::before {
      content: '';
      min-width: 18px;
      height: 18px;
      // background-image: url("assets/images/icons/mediamatcherM.svg");
    }
  }

  ul {
    padding: 10px 0;

    li {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      font-size: 14px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: '';
        position: relative;
        top: 4px;
        min-width: 18px;
        height: 18px;
        background-image: url("assets/images/icons/check.svg");
      }
    }
  }
}

.lead-srch {
  height: 100px;
  width: 100px;
  margin: 0 auto;
  border-radius: 100px;
  background-color: #ebeaea;

  .icon {
    margin: 30px;
    font-size: 40px;
  }
}

.lead-checkbox {
  align-items: flex-start !important;

  span {
    padding-top: 0 !important;
  }
}

.dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.dot {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #b3d4fc;
  animation: pulse 1.5s infinite ease-in-out;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dot:last-child {
  margin-right: 0;
}

.dot:nth-child(1) {
  animation-delay: -0.3s;
}

.dot:nth-child(2) {
  animation-delay: -0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    background-color: #4096DD;
    // box-shadow: 0 0 0 0 #c5ebd2;
  }

  50% {
    transform: scale(1.2);
    background-color: #4096DD;
    // box-shadow: 0 0 0 10px#c5ebd2;
  }

  100% {
    transform: scale(0.8);
    background-color: #4096DD;
    // box-shadow: 0 0 0 0 #c5ebd2;
  }
}


.filter-card {
  &.mobile {
    display: none;
  }

  &.desktop {
    display: flex;
    height: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 2%;
  }
}

.banner-info {
  p {
    text-align: center;
    font-size: 20px;
  }
}

// .banner-info {
//   &.desktop {
//     font-size: 20px;
//     text-align: center;
//   }

//   &.mobile {
//     font-size: 16px;
//     display: none;
//     text-align: center;
//   }
// }

.top-header {
  .top-header-content {
    display: flex;
    justify-content: center;
    color: #333;
    align-items: center;
    padding: 0 15px;
    border-bottom: 1px solid #e2e2e2;

    .top-header-left {
      font-size: 12px;
      line-height: 10px;
      color: #222;
      padding: 7px 0 8px 0;
    }

    .top-header-right {
      margin-left: 30px;

      .top-header-btn {
        cursor: pointer;
        text-decoration: underline;
        color: #222;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        text-align: right;
        white-space: nowrap;
        position: relative;
        z-index: 1000;
      }
    }
  }
}

.top-header-tooltip-content {
  font-size: 12px !important;
  display: inline-block;
}

.advertiser-tooltip {
  margin-left: 3px;

  >.top-header-tooltip-content {
    text-decoration: underline;
    font-weight: 600;
  }

  &.mobile {
    display: none;
  }

  &.desktop {
    display: inline-flex;
  }
}

.MuiTooltip-tooltip {
  background-color: #f5f5f9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  max-width: 375px !important;
  font-size: 14px !important;
  border: 1px solid transparent;
  padding: 20px !important;
  box-shadow: 0 3px 11px rgba(0, 0, 0, .2);
  border-radius: 0 !important;
}

@media screen and (max-width:1031px) {

  .editorial-reviews,
  .total-clicked-box {
    display: none;
  }



  .short-info {
    .MuiContainer-root {
      >div {


        .info {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }

  .blog-img {
    flex-direction: column;

    .blog-info-box {
      margin-left: 0;
      width: 90%;
      margin-top: -100px;
    }

    .img-wrapper {
      width: 100%;

      img {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  .blog-info-outer {
    flex-direction: column;

    .img-wrapper {
      width: 100%;

      img {
        width: 100%;
        max-width: 100%;
      }
    }

    .blog-info-box {
      width: 90%;
      margin-left: 0;
      margin-top: -60px;
    }
  }
}

.minW-768-inline {
  display: none !important;
}

@media screen and (max-width:799px) {

  .header-logo {
    margin: 0 auto;
  }
  
  .lead_forms {
    flex-direction: column;
    .lead_forms_left_content {
      width: 100%;
      max-width: 100%;
      padding: 25px 16px 0px !important;
      gap: 20px;
      flex-direction: column;
      img {
        max-width: 150px;
      }
      .count {
        position: absolute;
        top: 32px;
        left: 20px;
      }
    }
    .lead_forms_right_content {
      max-width: 100%;
      min-width: 100%;
    }
  }

  .filter-card {
    &.mobile {
      display: flex;
    }

    &.desktop {
      display: none;
    }
    .mobile-widget{
      margin-bottom: 17px !important; 
    }
  }

  .top-header-left {
    display: none;
  }

  .listing-card {
    flex-direction: column;

    .info-1 {
      width: 100%;
      max-width: 100%;
      padding: 25px 16px 0px !important;
      gap: 20px;
      flex-direction: column;

      img {
        max-width: 150px;
      }

      .count {
        position: absolute;
        top: 32px;
        left: 20px;
      }
    }

  }

  .mobile-listing-card {
   
    margin-bottom: 30px;
    cursor: pointer;
  
    .list-rank-count {
      position: absolute;
      left: 0;
      top: 0;
      background-color: $gray-300;
      font-size: 16px;
      height: 38px;
      min-width: 38px;
      border-bottom-right-radius: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-indent: -3px;
  
      &.first {
        background-color: $primary;
        color: $white;
      }
    }
  
  
    .info-2 {
      flex-grow: 1;
      padding: 16px;
      padding-bottom: 0 !important;
      .mBrandName {
        text-align: center;
      }
  
      .subtitle {
        display: flex;
        gap: 8px;
      }
  
      ul {
        padding: 10px 0;
        margin: 0 auto;
        li {
          display: flex;
          align-items: flex-start;
          gap: 8px;
          font-size: 14px;
          margin-bottom: 10px;
  
          &:last-child {
            margin-bottom: 0;
          }
  
          &::before {
            content: '';
            position: relative;
            top: 4px;
            min-width: 18px;
            height: 18px;
            background-image: url('assets/images/icons/check.svg');
          }
        }
      }
    }
  }
  
  .footer {
    .MuiGrid-grid-md-9 {
      margin-top: 20px;

      p {
        margin-top: 10px;
      }
    }

    .footer-links>div {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }

  .short-info {
    .MuiContainer-root {
      >div {
        flex-direction: column;
        gap: 16px;

        .uni-info {
          flex-direction: column;
          gap: 16px;
        }
      }
    }

  }

  .blog-info.info-2 {
    padding-top: 250px;
  }

  .header-logo {
    margin: 0 auto;
  }

  .banner-content {
    h1 {
      margin-top: 20px;
      line-height: 1;
    }

    h1:first-child {
      font-size: 28px !important;
    }

    h1:nth-child(2) {
      font-size: 26px !important;
    }

    p {
      margin-top: 10px;
      font-size: 16px;
    }
  }

  .top-header {
    .top-header-content {
      flex-direction: column;

      .top-header-left,
      .top-header-right {
        width: 100%;
      }

      .top-header-left {
        line-height: 1.2;
      }

      .top-header-right {
        padding: 0px 10px 10px;
      }
    }
  }
}


@media screen and (max-width:575px) {
  .radio-btn {
    width: 100%;
  }

  .banner-head-mobile {
    display: none;
  }

  .banner-section .banner-content h1:first-child {
    margin-top: 0;
  }

  .banner-info {
    p {
      display: flex;
      font-size: 16px;
      justify-content: center;
    }
  }
}

@media only screen and (min-width: 1023px) {
  .brand-name {
    padding: 5px 0 ;
  }

  .mw-1170{
    max-width: 1170px !important;
  }
}

@media screen and (max-width: 768px) {
  // .brand-name {
  //   font-size: 1.18rem !important;
  .mw-768-d-none {
    display: none !important;
  }
  .minW-768-inline {
    display: inline !important;
  }
  }

// @media only screen and (min-width: 768px) and (max-width: 1023px) {
  .brand-name {
    font-size: 1.18rem !important;
  }
// }

.inner-span {
  line-height: 1.5;
  font-size: 32px;
}

.custom-progress-bar .progress-bar {
  background-color: var(--custom-progress-bar-color, #6BA577); 
}
.frm-card {
  border: none;
  cursor: pointer;
  outline: 1px solid #ccc;
  &.selected,
  &:hover {
   // outline: 2px solid #4fab78;
    transition: all 50ms ease;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08) !important;
  }
}
.lead_forms {
  display: flex;
  margin-bottom: 30px;
  .list-rank-count {
    position: absolute;
    left: 0;
    top: 0;
    background-color: $gray-300;
    font-size: 16px;
    height: 38px;
    min-width: 38px;
    border-bottom-right-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-indent: -3px;
    &.first {
      background-color: $primary;
      color: $white;
    }
  }
  .lead_forms_left_content {
    max-width: 20%;
    min-width: 20%;
    padding: 65px 16px 40px;
    border-right: 1px solid $gray-200;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .lead_forms_right_content {
    flex-grow: 1;
    padding: 16px;
    max-width: 80%;
    min-width: 80%;
  }
}